import React, { useState } from 'react';
import { navigate } from 'gatsby';

import { PATH_PRODUCT } from 'utils/constants/paths';

import Down from '../../images/icons/i-down.svg';
import styled from 'styled-components';

const ProductItem = styled.div`
  box-sizing: border-box;
  display: flex;
  padding-top: 16px;
  line-height: 23px;
  color: #333;
`;
const ProductQty = styled.div`
  background: #f2f2f2;
  font-size: 16px;
  color: #000000;
  height: 22px;
  min-width: 22px;
  text-align: center;
  line-height: 22px;
`;
const ProductName = styled.div`
  flex: 0 0 260px;
`;

const ProductPrice = styled.div`
  margin-left: auto;
`;
const PromotionWrapper = styled.div`
  padding: 10px 2px 5px 22px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
const PromotionBlock = styled.div`
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 6px;
  gap: 10px;
  justify-content: space-between;
  width: auto;
  height: 18px;
  left: 15px;
  top: 76px;

  background: #fdf8e4;
  border: 1px solid #f2ecd2;
  border-radius: 20px;
`;
const PromotionLabel = styled.span`
  width: auto;
  height: 14px;
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`;
const DeleteText = styled.div`
  margin-top: 10px;
  width: 66px;
  background: #bef0f3;
  font-size: 16px;
  color: #000000;
  height: 22px;
  text-align: center;
  line-height: 20px;
`;

const OrderItem = ({ orderItem, refund = false }) => {
  const handleClickProduct = () => {
    navigate(`${PATH_PRODUCT}/${orderItem.productnumber}`);
  };

  return (
    <>
      <ProductItem onClick={handleClickProduct}>
        <ProductQty>{orderItem.qty}</ProductQty>
        <ProductName>
          {orderItem.item_name}
          {orderItem.sold_by_weight !== true && ` / ${orderItem.unit_quantity}`}
        </ProductName>
        <ProductPrice>
          {refund ? '-' : ''}${orderItem.subtotal}
        </ProductPrice>
      </ProductItem>
      {orderItem.calloutmsg !== '' && (
        <PromotionWrapper>
          <PromotionBlock>
            <PromotionLabel>{orderItem.calloutmsg}</PromotionLabel>
          </PromotionBlock>
        </PromotionWrapper>
      )}
    </>
  );
};
const OrderItemList = ({ orderItems, refundItems }) => {
  const [toggled, setToggled] = useState(false);
  const listClass = 'item-list' + (toggled ? ' toggled' : '');
  const onClickToggle = (event) => {
    event.preventDefault();
    if (toggled) {
      setToggled(false);
    } else {
      setToggled(true);
    }
  };
  return (
    <>
      <div className={listClass}>
        {orderItems.map((item, index) => (
          <OrderItem orderItem={item} key={`order-${index}`} />
        ))}
        {refundItems.length > 0 && <DeleteText>刪除商品</DeleteText>}
        {refundItems.map((item, index) => (
          <OrderItem orderItem={item} key={`refund-${index}`} refund="true" />
        ))}
      </div>
      <div className="toggle" onClick={onClickToggle}>
        {toggled ? '收起' : '展開更多'}
        <img src={Down} className="arrow" />
      </div>
      <style jsx>{`
        .item-list {
          border-top: 1px solid #f0f0f0;
          max-height: 62px;
          overflow-y: hidden;
          transition: all 0.5s;
          &.toggled {
            max-height: 9999px;
          }
        }
        .toggle {
          margin: 16px 0;
          padding: 8px 0 16px;
          border-top: 1px solid #f0f0f0;
          border-bottom: 1px solid #f0f0f0;
          text-align: center;
          color: #333333;
          .arrow {
            margin-left: 10px;
            line-height: 23px;
          }
        }
        .toggled ~ .toggle {
          .arrow {
            transform: rotate(180deg);
          }
        }
      `}</style>
    </>
  );
};

export default OrderItemList;
